import React from 'react'
import { Card } from 'primereact/card'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
const disabled = true;
export const Contact = () => {
    return (
        <Card title="Contact me" subTitle="Fill out the form below to get in touch with me!" className="">
            <form class="flex flex-col gap-4">
                <label for="name" class="">Name:</label>
                <InputText disabled={disabled} id="name" name="name" className="p-2 rounded-md" />
                <label for="email" class="">Email:</label>
                <InputText disabled={disabled} id="email" name="email" className="p-2 rounded-md" />
                <label for="message" class="">Message:</label>
                <InputTextarea disabled={disabled} id="message" name="message" className="p-2 rounded-md" />
                <div class="flex justify-center">
                    <Button disabled={disabled} type="submit" className="" icon="pi pi-envelope" iconPos="right" label='Submit' onClick={
                        (e) => {
                            e.preventDefault();
                        }
                    } />
                </div>
            </form>
        </Card>
    );
}