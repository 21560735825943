import React from 'react'
import { Menubar } from 'primereact/menubar';
import { Image } from 'primereact/image';
import logo from '../assets/logo.svg';
import { Link } from 'react-router-dom';

export default function PDMenubar() {
    const items = [
        {
            label: 'Contact',
            icon: 'pi pi-fw pi-envelope',
            url: '/contact'
        }
    ];

    return (
        <Menubar model={items}
            start={<Link to="/"><Image src={logo} alt="logo" width="80" /></Link>}
        />
    )
}