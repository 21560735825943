import './App.css';

import { Home } from './components/pages/Home';
import { Contact } from './components/pages/Contact';
import React from 'react';
import { PrimeReactProvider } from 'primereact/api';
import PDMenubar from './components/PDMenubar';
import { Route, Routes } from 'react-router-dom';

export default function App() {

  const headerFooterClass = "w-full h-10rem bg-blue-500 flex justify-center items-center text-white p-2 text-sm md:text-base";

  return (
    <PrimeReactProvider>
      <header class={headerFooterClass}>
        Disclaimer: This is a demo site. It is not functional.
      </header>

      <PDMenubar />

      <main class="w-full flex justify-center items-center" style={{ flex: "1 1 100%" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

      </main>
      <footer class={headerFooterClass}>
        In development by Sahil Khan
      </footer>
    </PrimeReactProvider>
  );
}


